document.addEventListener('DOMContentLoaded', initPage);

function initPage() {
  function checkScroll() {
    if (window.scrollY > 0) {
      document.body.classList.add('is-scrolled');
    } else {
      document.body.classList.remove('is-scrolled');
    }
  }

  checkScroll();

  window.addEventListener('scroll', checkScroll);

  document.querySelector('.toggler').addEventListener('click', function () {
    document.body.classList.toggle('is-mobile-menu');
  });

  const galleryElements = document.querySelectorAll('#service-gallery a');

  // for (const element of galleryElements) {
  //   getImageDimensions(element.href)
  //     .then(function (dimensions) {
  //       element.setAttribute('data-pswp-width', dimensions.width);
  //       element.setAttribute('data-pswp-height', dimensions.height);
  //     })
  //     .catch(function (error) {
  //       console.error('Error getting image dimensions:', error);
  //     });
  // }

  // function getImageDimensions(url) {
  //   return new Promise(function (resolve, reject) {
  //     const img = new Image();
  //     img.onload = function () {
  //       resolve({ width: img.width, height: img.height });
  //     };
  //     img.onerror = function () {
  //       reject(new Error('Failed to load image at ' + url));
  //     };
  //     img.src = url;
  //   });
  // }

  const leftArrowSVGString =
    '<svg xmlns="http://www.w3.org/2000/svg" width="647.04" height="970.32" viewBox="0 0 647.04 970.32"><rect x="47.38" y="370.98" width="228.76" height="228.76" transform="translate(-295.82 256.54) rotate(-45)" style="fill:#fff"/><rect x="370.9" y="694.18" width="228.76" height="228.76" transform="translate(-429.6 579.97) rotate(-45)" style="fill:#fff"/><rect x="370.9" y="47.37" width="228.76" height="228.76" transform="translate(27.76 390.53) rotate(-45)" style="fill:#fff"/></svg>';

  const rightArrowSVGString =
    '<svg xmlns="http://www.w3.org/2000/svg" width="647.04" height="970.32" viewBox="0 0 647.04 970.32"><rect x="370.9" y="370.57" width="228.76" height="228.76" transform="translate(-200.78 485.19) rotate(-45)" style="fill:#fff"/><rect x="47.38" y="47.37" width="228.76" height="228.76" transform="translate(-67 161.76) rotate(-45)" style="fill:#fff"/><rect x="47.38" y="694.18" width="228.76" height="228.76" transform="translate(-524.36 351.21) rotate(-45)" style="fill:#fff"/></svg>';

  const lightbox = new PhotoSwipeLightbox({
    gallery: '#service-gallery',
    bgOpacity: 1,
    zoom: false,
    children: 'a',
    showHideAnimationType: 'fade',
    arrowPrevSVG: leftArrowSVGString,
    arrowNextSVG: rightArrowSVGString,
    pswpModule: PhotoSwipe,
  });
  lightbox.init();
}
